import * as React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import SpyCam from "../components/SpyCam";
import http from "../utils/http";
import moment from "moment";
import CandidateProactor from '../components/Agora/CandidateProactor';
import AudioRecorder from "../components/AudioRecorder";
// import Speech from 'react-speech';
import {
    createClient,
} from "agora-rtc-react";
import CaptchaAlert from '../components/CaptchaAlert';
let config = {
    mode: "rtc", codec: "vp8", appId: process.env.REACT_APP_AGORA_APP_ID, token: ''
};
const useClient = createClient(config);

let hidden = null;
let visibilityChange = null;
if (typeof document.hidden !== 'undefined') { // Opera 12.10 and Firefox 18 and later support 
    hidden = 'hidden';
    visibilityChange = 'visibilitychange';
} else if (typeof document.msHidden !== 'undefined') {
    hidden = 'msHidden';
    visibilityChange = 'msvisibilitychange';
} else if (typeof document.webkitHidden !== 'undefined') {
    hidden = 'webkitHidden';
    visibilityChange = 'webkitvisibilitychange';
}

const Exam = () => {
    const client = useClient();
    // const dispatch = useDispatch();
    const navigate = useNavigate();
    const spyCam = React.useRef();
    const { id } = useParams();
    const user = useSelector(state => state.auth.user);
    const { startAudioRecording, stopAudioRecording } = AudioRecorder(`${id}-${user._id}`);
    const [exam, setExam] = React.useState({});
    // const [languages, setLanguages] = React.useState([]);
    const [questions, setQuestion] = React.useState([]);
    const [second_lang, setSecondLang] = React.useState({});
    const [audioUrl, setAudioUrl] = React.useState();
    const [active, setActive] = React.useState(0);
    const [counter, setCounter] = React.useState(null);
    const [qsSts, setQsSts] = React.useState(false);
    const [finalSts, setFinalSts] = React.useState(false);
    const [violation, setViolation] = React.useState(0);
    const [violationTime, setViolationTime] = React.useState(null);
    const [violation_status, setViolationStatus] = React.useState(false);
    const [feedbackEnable, setFeedbackEnable] = React.useState(false);
    const [typingTest, setTypingTest] = React.useState(false);
    const [spendTime, setSpendTime] = React.useState(0);

    const getExams = React.useCallback(() => {
        http.get(`/api/v1/exam-question/${id}`).then((res) => {
            if (res.data.last_active > 0) {
                setActive(res.data.last_active);
            }
            setExam(res.data.assessment);
            // setLanguages(res.data.set.languages);
            setQuestion(res.data.questions.map((v, i) => {
                return { ...v, visited: active === i ? true : false };
            }));
            setSecondLang(res.data.languages.find(v => v.slug !== 'eng') || {});
            let endDate = 0;
            if (res.data.rest_time > 0) {
                endDate = moment().add(res.data.rest_time, 'seconds');
            } else {
                endDate = moment().add(res.data.assessment.strategy.duration, 'minutes');
                // endDate = moment().add(1, 'minutes');
            }

            let counterTime = endDate.diff(moment(), 'seconds');
            setCounter(counterTime);
            setSpendTime(counterTime);
            if (res.data.assessment.proctor_allow) {
                http.post(`/api/v1/agora/rtc/${id}/audience/uid/0`).then((res) => {
                    config['token'] = res.data.rtcToken;
                });
            }
            if (res.data.assessment?.exam_audio_enable) {
                startAudioRecording();
            }
            if (res.data.assessment?.feedback_enable) {
                setFeedbackEnable(true)
            }
            if (res.data.assessment?.typing_exam) {
                setTypingTest(true)
            }
        }).catch(error => {
            navigate('/');
        });
    }, [id, navigate]);

    React.useEffect(() => {
        getExams()
    }, [getExams]);

    React.useEffect(() => {
        var timer = null;
        if (counter !== null && questions.length > 0) {
            timer = setInterval(() => {
                if (counter === 0) {
                    submitAnswer(active, null, true);
                } else {
                    setCounter(counter - 1);
                }
            }, 1000);
        }
        return () => clearInterval(timer);
    }, [questions, counter]);

    React.useEffect(() => {
        if (exam && exam.violation_alert > 0 && violation === exam.violation_alert) {
            submitAnswer(active, '', true);
        }
    }, [violation]);

    React.useEffect(() => {
        function openFullscreen() {
            var elem = document.documentElement;
            if (elem.requestFullscreen) {
                elem.requestFullscreen();
            } else if (elem.webkitRequestFullscreen) { /* Safari */
                elem.webkitRequestFullscreen();
            } else if (elem.msRequestFullscreen) { /* IE11 */
                elem.msRequestFullscreen();
            }
        }
        function getViolation() {
            if (exam && exam.violation_alert > 0 && document[hidden]) {
                setViolationTime(new Date());
                setViolationStatus(true);
                setViolation(violation + 1);
            }
        }

        document.documentElement.onclick = openFullscreen;
        document.onkeydown = openFullscreen;
        document.addEventListener('contextmenu', (e) => {
            e.preventDefault();
        });
        document.addEventListener(visibilityChange, getViolation, false);

        return () => {
            document.documentElement.onclick = null;
            document.onkeydown = null;
            document.removeEventListener('contextmenu', null);
            document.removeEventListener(visibilityChange, null, false);
        };
    });

    if (questions.length === 0) {
        return <div className="flex h-screen w-screen items-center justify-center">
            <svg className="animate-spin h-5 w-5 mr-3" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
        </div>
    }

    const getLangQuestion = (index, lang = 'eng') => {
        if (questions.length === 0) return '';
        let q = questions[index].question.question.find(v => v.lang === lang);
        return q?.content;
    };

    const getQuestionType = (index, lang = 'eng') => {
        if (questions.length === 0) return '';
        let q = questions[index];
        return q?.type;
    };

    const textToVoice = (text, lang = 'eng') => {
        http.post(`/api/v1/ai/text-speech`, {
            text: text,
            lang: lang
        }).then((res) => {
            setAudioUrl(res.data.google.audio_resource_url)
        }).catch((err) => {
            console.error(err)
        })
    };

    const getQuestionImage = (index) => {
        if (questions.length === 0) return '';
        let q = questions[index].question.question.find(v => v.lang === "eng");
        if (q.image) {
            return q.image
        }
        return false
    }

    const getLangOptionsData = (index, lang = 'eng') => {
        if (questions.length === 0) return [];
        return questions[index].question.question.find(v => v.lang === lang)?.options;
    };

    const counterClass = (index) => {
        if (questions.length === 0) return 'bg-black';
        if (index === active) return 'bg-blue';
        if (questions[index].answer) return 'bg-green';
        if (questions[index].review && questions[index].answer === null && index !== active) return 'bg-yellow !important';
        if (questions[index].visited && questions[index].answer === null && !questions[index].review) return 'bg-red !important';
        return 'bg-black';
    };

    const changeAnswer = (value, index) => {
        if (questions.length === 0) return '';
        let q = [...questions];
        q[index].answer = value;
        q[index].spend_time = q[index].spend_time + (spendTime - counter);
        setQuestion(q);
        if (exam.spy_camera_capture) {
            spyCam.current.takeSpyImage();
        } else {
            submitAnswer(index, null);
        }
    };

    const counterClck = (index, closeModel = false) => {
        if (closeModel) {
            setQsSts(false)
        }
        if (questions.length === 0) return '';
        let q = [...questions];
        q[active].visited = true;
        q[active].spend_time = q[active].spend_time + (spendTime - counter);
        setQuestion(q);
        setActive(index);
        setSpendTime(counter);
    };

    const reAttempt = () => {
        setQsSts(false)
        if (questions.length === 0) return '';
        let q = [...questions];
        q[active].visited = true;
        q[active].spend_time = q[active].spend_time + (spendTime - counter);
        setQuestion(q);

        // Find the index of the first question with answer === null
        const index = q.findIndex(qu => qu.answer === null);

        // If an unanswered question is found, set it as active
        if (index !== -1) {
            setActive(index);
            setSpendTime(counter);
        }
    };

    const saveNext = (index) => {
        if (questions.length === 0 || questions.length - 1 === index) return false;
        counterClck(index + 1);
        setSpendTime(counter);
    };

    const savePrev = (index) => {
        if (questions.length === 0 || index === 0) return false;
        counterClck(index - 1);
        setSpendTime(counter);
    };

    const reviewLetter = (index) => {
        if (questions.length === 0) return '';
        let q = [...questions];
        q[index].review = true;
        counterClck(index + 1);
        setQuestion(q);
    };

    const submitAnswer = (index, image = null, final_submit = false) => {
        const f = new FormData();
        if (final_submit) {
            f.append('final_submit', true);
            if (exam && exam.violation_alert > 0) {
                if (violation > 0) {
                    f.append('violation', violation);
                    f.append('violation_date', violationTime);
                    f.append('rest_time', counter);
                    f.append('last_active', index);
                }
            }
            setQsSts(false);
            if (exam.exam_audio_enable) {
                stopAudioRecording();
            }
        } else {
            if (image) {
                f.append('image', image, 'spy.png');
            }
            let q = { ...questions[index] };
            f.append('question', q.question._id);
            f.append('answer', q.answer);
            f.append('time', new Date());
            f.append('review', q.review);
            f.append('rest_time', counter);
            f.append('last_active', index);
            f.append('spend_time', q.spend_time);
            f.append('type', q.type);
        }

        http.post(`/api/v1/submit-answer-web/${id}`, f, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then((res) => {
            if (final_submit) {
                setFinalSts(true);
                setTimeout(() => {
                    logout();
                }, 6000);
            }
        }).catch(error => {

        })
    };

    const getSpyImage = (image, index) => {
        submitAnswer(index, image);
    }

    const pad = (num) => {
        return ("0" + num).slice(-2);
    }

    const hhmmss = (secs) => {
        var minutes = Math.floor(secs / 60);
        secs = secs % 60;
        var hours = Math.floor(minutes / 60)
        minutes = minutes % 60;
        return `${pad(hours)}:${pad(minutes)}:${pad(secs)}`;
    }

    const logout = () => {
        if (feedbackEnable) {
            navigate(`/exam/feedback/${id}`);
        } else {
            window.location.href = '/';
        }
    };

    if (violation_status) {
        return <CaptchaAlert active={violation_status} onClose={setViolationStatus} violation_count={exam.violation_alert} violation />
    }

    return <div>
        {/* Show Question Type */}
        {!finalSts && getQuestionType(active) === 'mcq_practical' && <div className='bg-blue-600 w-full p-2 text-white font-bold'>MCQ Practical</div>}
        {!finalSts && <div className='grid lg:grid-cols-4 grid-cols-5 gap-4'>
            {exam.spy_camera_capture && <SpyCam ref={spyCam} onChange={(e) => getSpyImage(e, active)} />}
            {exam.proctor_allow && <CandidateProactor config={config} client={client} channelName={id} candidate={user} />}
            <div className='col-span-3'>
                <div className='question'>
                    <ol className='orderRules' type='1' start={active + 1}>
                        <li>
                            <div>
                                <div className='eng inline-block' dangerouslySetInnerHTML={{ __html: getLangQuestion(active) }}></div>
                                <img src="/mic.jpg" alt="speech" className='inline-block' onClick={() => textToVoice(getLangQuestion(active))} width="20px" height="20px" style={{ cursor: "pointer" }} />
                            </div>
                            <div>
                                {Object.keys(second_lang).length > 0 && getLangQuestion(active, second_lang.slug) && <>
                                    <div className={`${second_lang.slug} inline-block`} dangerouslySetInnerHTML={{ __html: getLangQuestion(active, second_lang.slug) }}></div>
                                    <img src="/mic.jpg" alt="speech" className='inline-block' onClick={() => textToVoice(getLangQuestion(active, second_lang.slug), second_lang.slug)} width="20px" height="20px" style={{ cursor: "pointer" }} />
                                </>}
                            </div>
                            <audio src={audioUrl} autoPlay controls style={{ display: "none" }}></audio>
                            {getQuestionImage(active) && <img src={getQuestionImage(active)} style={{ width: "350px" }} alt="question" title="question" />}
                        </li>
                    </ol>
                </div>
                <div className='options input-m'>
                    {getLangOptionsData(active).length > 0 && getLangOptionsData(active).map((opt, oi) => <div key={oi} className="flex items-start mb-6">
                        <div className="flex items-center h-5 lg:option-m">
                            <input
                                id={`terms-${opt._id}`} // Add unique ID for each input
                                type="radio"
                                name={`name-${active + 1}`}
                                value={opt._id}
                                onChange={(e) => changeAnswer(e.target.value, active)}
                                checked={(questions.length > 0 && questions[active].answer === opt._id) ? true : false}
                                className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800"
                            />
                        </div>
                        <label
                            htmlFor={`terms-${opt._id}`} // Add corresponding ID of the input
                            className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300 flex flex-col"
                        >
                            <div>
                                <span className="eng-opt inline-block" dangerouslySetInnerHTML={{ __html: opt.content }}></span>
                                <img src="/mic.jpg" className="inline-block" alt="speech" onClick={() => textToVoice(opt.content)} width="20px" height="20px" style={{ cursor: "pointer" }} />
                            </div>
                            <div>
                                {Object.keys(second_lang).length > 0 && getLangOptionsData(active, second_lang.slug)[oi].content && (
                                    <>
                                        <span className={`${second_lang.slug}-opt inline-block`} dangerouslySetInnerHTML={{ __html: getLangOptionsData(active, second_lang.slug)[oi].content }}></span>
                                        <img src="/mic.jpg" className="inline-block" alt="speech" onClick={() => textToVoice(getLangOptionsData(active, second_lang.slug)[oi].content, second_lang.slug)} width="20px" height="20px" style={{ cursor: "pointer" }} />
                                    </>
                                )}
                            </div>
                            {opt.image && <img src={opt.image} width="250px" style={{ width: "250px" }} alt="option" title="option" />}
                        </label>
                    </div>)}
                </div>
            </div>
            <div className='lg:col-span-1 col-span-2'>
                <div className="time-left lg:text-[21px]">
                    Time left: {hhmmss(counter)}
                </div>
                <div className="assess-de lg:text-[17px]">
                    <p>Assessment Details</p>
                    <ol className="assess-list lg:pl-[20px]">
                        <span className="assess-span"><li>Candidate Name:{user?.name}</li></span>
                        <span className="assess-span"><li>SSC:{exam?.sector?.name}</li></span>
                        <span className="assess-span"><li>QP:{exam?.jobrole?.name}</li></span>
                    </ol>
                </div>
                <div className="assess-de mb-[70px]">
                    <p>Assessment Details</p>
                    <div className='overflow-y-auto' style={{ maxHeight: 'calc(100vh - 410px)' }}>
                        <div className='grid lg:grid-cols-7 grid-cols-4 lg:gap-2 gap-[3px] py-2 px-2'>
                            {questions.length > 0 && questions.map((v, i) => <button key={i} className={`w-8 h-8 rounded-full text-white ${counterClass(i)}`} onClick={() => counterClck(i)}>{i + 1}</button>)}
                        </div>
                    </div>
                    <div className="assess-bott contents">
                        <div className="rev-com lg:text-[14px] lg:mr-[15px]"><span className="common-cls bg-yellow"></span>Review Later</div>
                        <div className="rev-com lg:text-[14px] lg:mr-[15px]"><span className="common-cls bg-red"></span>UnAnswered</div>
                        <div className="rev-com lg:text-[14px] lg:mr-[15px]"><span className="common-cls bg-black"></span>Not Visited</div>
                        <div className="rev-com lg:text-[14px] lg:mr-[15px]"><span className="common-cls bg-blue"></span>Active</div>
                        <div className="rev-com lg:text-[14px] lg:mr-[15px]"><span className="common-cls bg-green"></span>Answered</div>
                    </div>
                </div>
            </div>
            <div className='footerbottom lg:pl-50px col-span-4'>
                <button type="button" className="text-white w-1/4 lg:w-auto cursor-pointer bg-blue-700 hover:bg-green-500 hover:text-white focus:ring-4 focus:outline-none font-medium rounded-lg text-sm lg:px-5 px-[0.1rem] lg:py-2.5 py-[0.5rem] text-center rev-lat mr-2" disabled={active === 0 ? true : false} onClick={() => savePrev(active)}>Previous</button>
                {((questions.length - 1) !== active) && <button type="button" className={`text-white w-[31%] lg:w-auto cursor-pointer bg-blue-700 hover:bg-green-500 hover:text-white focus:ring-4 focus:outline-none font-medium rounded-lg text-sm lg:px-5 px-[0.1rem] lg:py-2.5 py-[0.5rem] text-center rev-lat mr-2`} disabled={((questions.length - 1) === active) ? true : false} onClick={() => saveNext(active)}>Save &amp; Next</button>}
                {((questions.length - 1) !== active) && <button type="button" className="text-white cursor-pointer w-[31%] lg:w-auto bg-blue-700 hover:bg-green-500 hover:text-white focus:ring-4 focus:outline-none font-medium rounded-lg text-sm lg:px-5 px-[0.1rem] lg:py-2.5 py-[0.5rem] text-center rev-lat mr-2" disabled={(questions.length > 0 && questions[active].answer === '') ? true : ''} onClick={() => reviewLetter(active)}>Review Later</button>}
                {((questions.length - 1) === active) && <button type="button" className="text-white w-1/4 cursor-pointer lg:w-auto bg-blue-700 hover:bg-green-500 hover:text-white focus:ring-4 focus:outline-none font-medium rounded-lg text-sm lg:px-5 px-[0.1rem] lg:py-2.5 py-[0.5rem] text-center rev-lat" onClick={() => setQsSts(true)}>Submit</button>}
            </div>
        </div>}
        {finalSts && <div className='h-screen flex items-center justify-center'>
            <div className='w-auto text-center'>
                <div className='text-2xl text-green-600 font-bold'>Exam Submit Successfully.</div>
                <div className='text-lg mt-2'>By {user?.name} ({user?.batch?.batch_id})</div>
                <div className='text-lg mt-2'>Now you can click below to {feedbackEnable ? "feedback form" : "logout"} or auto redirect after 6 sec.</div>
                <button type='button' onClick={() => logout()} className='mt-5 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800'>Go To {feedbackEnable ? "Feedback Form" : "Home"}</button>
            </div>
        </div>}
        {qsSts ? (
            <>
                <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none" style={{ zIndex: 101 }} >
                    <div className="relative w-auto my-6 mx-auto max-w-3xl">
                        {/*content*/}
                        <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                            {/*header*/}
                            <div className="flex items-start justify-between p-4 border-b border-solid border-slate-200 rounded-t">
                                <h3 className="text-xl font-medium">
                                    Exam Status
                                </h3>
                            </div>
                            {/*body*/}
                            <div className="relative p-4 flex-auto overflow-y-auto" style={{ width: 250 }}>
                                <div className="grid grid-cols-5 gap-2">
                                    <div className="col-span-3">Total Question</div>
                                    <div className="col-span-1">:</div>
                                    <div className="col-span-1">{questions.length}</div>

                                    <div className="col-span-3">Attempted</div>
                                    <div className="col-span-1">:</div>
                                    <div className="col-span-1">{questions.filter(v => v.answer).length}</div>

                                    <div className="col-span-3">Reviews</div>
                                    <div className="col-span-1">:</div>
                                    <div className="col-span-1">{questions.filter(v => !v.answer && v.review).length}</div>

                                    <div className="col-span-3">Not Attempted</div>
                                    <div className="col-span-1">:</div>
                                    <div className="col-span-1">{questions.filter(v => !v.answer && !v.review).length}</div>
                                </div>
                            </div>
                            {questions.filter(v => !v.answer).length > 0 &&
                                <div>
                                    <div className="p-4 border-t border-solid border-slate-200 rounded-t">
                                        <div className="font-medium">
                                            Unanswered Questions :
                                        </div>
                                        <div className='grid lg:grid-cols-8 grid-cols-4 lg:gap-2 gap-[3px] py-2 px-2'>
                                            {questions.map((qe, i) => {
                                                return qe.answer === null && <button key={i} className={`w-8 h-8 rounded-full text-white ${counterClass(i)}`} onClick={() => counterClck(i, true)}>{i + 1}</button>
                                            })}
                                        </div>
                                    </div>
                                    <div className="p-4 border-t border-solid border-slate-200 rounded-t">
                                        Kindly attempt all unanswered questions and submit again
                                    </div>
                                </div>
                            }


                            {/*footer*/}
                            <div className="flex items-center justify-end p-2 border-t border-solid border-slate-200 rounded-b">
                                <button
                                    className="text-red-500 background-transparent font-medium px-5 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                    type="button"
                                    onClick={() => reAttempt()}
                                >
                                    Go Back
                                </button>
                                {!(questions.filter(v => !v.answer).length > 0) &&
                                    <button
                                        className="bg-blue-700 text-white active:bg-blue-600 font-medium text-sm px-5 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={() => submitAnswer(active, null, true)}
                                    >
                                        Submit
                                    </button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="opacity-25 fixed inset-0 z-40 bg-black" style={{ zIndex: 100 }}></div>
            </>
        ) : null}
    </div>;
};

export default Exam;