import { useEffect, useRef, useState, useCallback } from "react";
import moment from "moment";

navigator.getUserMedia = (navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia);

const Camera = ({ previous = null, onChange, location, address }) => {
    const video = useRef();
    const canvas = useRef();
    const snapshot = useRef();
    const [image, setImage] = useState(null);
    const [stream, setStream] = useState(null);
    const [backCamera, setBackCamera] = useState(true);

    const startStreaming = useCallback(() => {
        if (null == stream) {
            navigator.mediaDevices.getUserMedia({ video: true })
                .then(function (mediaStream) {
                    setStream(mediaStream);
                    video.current.srcObject = mediaStream;
                    video.current.play();
                })
                .catch(function (err) {
                    console.error("Unable to access camera: " + err);
                });
        }
    }, [stream]);

    const BackCamera = async () => {
        let track = await stream.getTracks()[0];
        await track.stop();
        video.current.load();

        let constraints = {
            video: {
                facingMode: { ideal: 'environment' }
            }
        };

        navigator.mediaDevices.getUserMedia(constraints)
            .then(function (mediaStream) {
                setStream(mediaStream);
                video.current.srcObject = mediaStream;
                video.current.play();
                setBackCamera(true)
                console.log("back true")
            })
            .catch(function (err) {
                console.error("Unable to switch camera: " + err);
            });
    };

    const FrontCamera = async () => {
        let track = await stream.getTracks()[0];
        await track.stop();
        video.current.load();

        navigator.mediaDevices.getUserMedia({ video: true })
            .then(function (mediaStream) {
                setStream(mediaStream);
                video.current.srcObject = mediaStream;
                video.current.play();
                setBackCamera(false)
                console.log("back false")
            })
            .catch(function (err) {
                console.error("Unable to switch camera: " + err);
            });

    }


    const stopStreaming = useCallback(() => {
        if (null != stream) {
            var track = stream.getTracks()[0];
            track.stop();
            video.current.load();
        }
    }, [stream]);

    useEffect(() => {
        startStreaming();
    }, [startStreaming]);



    const captureSnapshot = () => {
        if (null != stream) {
            var ctx = canvas.current.getContext('2d');
            var img = new Image();
            ctx.drawImage(video.current, 0, 0, canvas.current.width, canvas.current.height);
            // Get the current time
            const timeNow = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");

            // Get the current geolocation
            const latitude = location.lat;
            const longitude = location.lng;
            const geotag = `Lat: ${latitude}, Long: ${longitude}`;

            // Set the text properties
            ctx.fillStyle = 'white'; // Set text color to white
            ctx.font = '12px Arial'; // Set font and size

            // Draw the timestamps and geotag on the canvas
            ctx.fillText(timeNow, 4, canvas.current.height - 28);
            ctx.fillText(geotag, 4, canvas.current.height - 16);
            ctx.fillText(address, 4, canvas.current.height - 4);

            // Convert canvas to image and display it
            let src = canvas.current.toDataURL("image/png");
            setImage(src);
            img.src = src;
            img.width = 240;
            snapshot.current.innerHTML = '';
            snapshot.current.appendChild(img);

            // Stop streaming and handle the snapshot data
            stopStreaming();
            onChange(dataURItoBlob(src));
        }
    }

    const dataURItoBlob = (dataURI) => {
        var byteString = atob(dataURI.split(',')[1]);
        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

        var buffer = new ArrayBuffer(byteString.length);
        var data = new DataView(buffer);

        for (var i = 0; i < byteString.length; i++) {
            data.setUint8(i, byteString.charCodeAt(i));
        }

        return new Blob([buffer], { type: mimeString });
    }

    return <div className="flex flex-col justify-center items-center">
        <video ref={video} width="320" height="240" style={{ display: image ? 'none' : 'block' }}></video>
        <canvas ref={canvas} width="320" height="240" style={{ display: 'none' }}></canvas>
        <div ref={snapshot} style={{ display: image ? 'block' : 'none' }}></div>
        <div className="flex flex-row justify-between mt-2">
            <button type="button" onClick={() => captureSnapshot()} className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mr-2" style={{ opacity: image ? '0.5' : '1' }}>Capture Image</button>
            <button type="button" onClick={() => backCamera ? FrontCamera() : BackCamera()} className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-2 px-4 rounded mr-2" style={{ opacity: image ? '0.5' : '1' }}>Switch Camera</button>
            <button type="button" onClick={() => {
                setImage(null);
                setStream(null);
                startStreaming();
            }} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-2 rounded" style={{ opacity: image ? '1' : '0.5' }}>Start Camera</button>
        </div>
    </div>
};

export default Camera;