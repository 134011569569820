import * as React from 'react';
import { Outlet, Link, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { AiOutlineAlignRight } from "react-icons/ai";
import http from "../utils/http";
import { SET_EXAM } from '../store/common';

const Index = () => {
    const [open, setOpen] = React.useState(true)
    const [practical, setPractical] = React.useState(false)
    const dispatch = useDispatch();
    const user = useSelector(state => state.auth.user);
    const examData = useSelector(state => state.common.exams);
    const location = useLocation();
    const logout = () => {
        localStorage.clear();
        window.location.href = '/';
    };

    const getExamDetails = React.useCallback(() => {
        http.get('/api/v1/exam-list').then((res) => dispatch(SET_EXAM(res.data)));
    }, [dispatch]);

    React.useEffect(() => {
        getExamDetails()
    }, [getExamDetails])


    React.useEffect(() => {
        let getTypingTest = examData.find((e) => e.typing_exam)
        if (!getTypingTest) {
            const hasPractical = examData.some((exam) => exam.strategy.strategy.viva.length > 0 || exam.strategy.strategy.demo.length > 0);
            setPractical(hasPractical);
        }
    }, [examData]);

    return <div className='relative' >
        <div className="top-nav-bar w-full absolute">
            <div className='flex justify-end'>
                <button onClick={() => logout()} className='p-4 cursor-pointer'><img src='/shutdown.png' alt='logout' style={{ width: 30, height: 30 }} /></button>
            </div>
        </div>
        <div className='w-full'>
            <div className={`${open ? '' : 'w-2/5'} leftside  h-full `}>
                <aside className={`${open ? '' : 'w-1/2'} sidebar lg:w-64 aria-label="Sidebar`}>
                    <div className={`lg:logo bg-white text-center justify-center m-auto h-16`} style={{ maxWidth: '100%', maxHeight: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: '-webkit-center' }}>
                        <div className='lg:hidden' >
                            <div onClick={() => setOpen(!open)} className={`w-16 lg:logoskill setShow lg:align-middle lg:mx-auto my-0`} style={{ maxWidth: '100%', maxHeight: '100%' }} alt='logo'>
                                <AiOutlineAlignRight size={30} />
                            </div>
                        </div>
                        <img src="/logo.png" className={`w-16 lg:w-auto lg:block lg:logoskill ${open ? "hidden" : "block"} setShow lg:align-middle lg:mx-auto my-0`} style={{ maxWidth: '100%', maxHeight: '100%' }} alt='logo' />
                    </div>
                    <div className={`overflow-y-auto py-4 px-3 bg-white-50 rounded dark:bg-gray-800`}>
                        <ul className="space-y-2 space-f-s">
                            <li>
                                <Link to="/" className={`flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 active:bg-gray-100 dark:hover:bg-gray-700 ${location.pathname === '/' ? 'active' : ''}`}>
                                    <svg className="w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M2 10a8 8 0 018-8v8h8a8 8 0 11-16 0z"></path><path d="M12 2.252A8.014 8.014 0 0117.748 8H12V2.252z"></path></svg>
                                    <span className={`lg:block ${open && 'hidden'} ml-3`}>Dashboard</span>
                                </Link>
                            </li>
                            {user.role.name === 'candidate' ? <>
                                <li>
                                    <Link to="/exam-list" className={`flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 ${location.pathname === '/exam-list' ? 'active' : ''}`}>
                                        <svg className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clipRule="evenodd"></path></svg>
                                        <span className={`lg:block ${open && 'hidden'} flex-1 ml-3 whitespace-nowrap`}>Start Theory</span>
                                    </Link>
                                </li>
                                {practical && <li>
                                    <Link to="/practical" className={`flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 ${location.pathname === '/practical' ? 'active' : ''}`}>
                                        <svg className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clipRule="evenodd"></path></svg>
                                        <span className={`lg:block ${open && 'hidden'} flex-1 ml-3 whitespace-nowrap`}>Start Practical</span>
                                    </Link>
                                </li>
                                }
                            </> : <>
                                <li>
                                    <Link to="/batch-list" className={`flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 ${location.pathname === '/batch-list' ? 'active' : ''}`}>
                                        <svg className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clipRule="evenodd"></path></svg>
                                        <span className={`lg:block ${open && 'hidden'} flex-1 ml-3 whitespace-nowrap`}>Batch List</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/today-assessment" className={`flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 ${location.pathname === '/today-assessment' ? 'active' : ''}`}>
                                        <svg className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clipRule="evenodd"></path></svg>
                                        <span className={`lg:block ${open && 'hidden'} flex-1 ml-3 whitespace-nowrap`}>Today Assessment</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/upcomming-assessment" className={`flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 ${location.pathname === '/upcomming-assessment' ? 'active' : ''}`}>
                                        <svg className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clipRule="evenodd"></path></svg>
                                        <span className={`lg:block ${open && 'hidden'} flex-1 ml-3 whitespace-nowrap`}>Upcomming Assessment</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/pending-assessment" className={`flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 ${location.pathname === '/pending-assessment' ? 'active' : ''}`}>
                                        <svg className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clipRule="evenodd"></path></svg>
                                        <span className={`lg:block ${open && 'hidden'} flex-1 ml-3 whitespace-nowrap`}>Pending Assessment</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/completed-assessment" className={`flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 ${location.pathname === '/completed-assessment' ? 'active' : ''}`}>
                                        <svg className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clipRule="evenodd"></path></svg>
                                        <span className={`lg:block ${open && 'hidden'} flex-1 ml-3 whitespace-nowrap`}>Completed Assessment</span>
                                    </Link>
                                </li>
                                {/* <li>
                                    <Link to="/assessor-rating" className={`flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 ${location.pathname === '/completed-assessment' ? 'active' : ''}`}>
                                        <svg className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clipRule="evenodd"></path></svg>
                                        <span className={`lg:block ${open && 'hidden'} flex-1 ml-3 whitespace-nowrap`}>Assessor Rating</span>
                                    </Link>
                                </li> */}
                            </>}
                        </ul>
                    </div>
                </aside>
            </div>
            <div className={` h-full ml-16 pt-16 lg:ml-64 lg:rightsidetop`} >
                <section className={`h-full lg:rightsidetop`}>
                    <Outlet />
                </section>
            </div>
        </div>
    </div>
};

export default Index;